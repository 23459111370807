import React from 'react';
import { Link } from 'gatsby';
import Slider from 'react-animated-slider';

import Layout from '../components/layout';
import Seo from '../components/seo';

export default function IndexPage() {
    const slides = [
        {
            title: 'You Live Life',
            description: "We'll handle the paperwork",
            image: '../../static/8f2eb094e91982e1aef049c5b37f41e4/tl-banner-1.jpg',
        },
        {
            title: 'Since 1986',
            description: 'Dedication to Service',
            image: '../../static/cc5d06c92cabdb022efdf95b6c41d79f/tl-banner-2.jpg',
        },
        {
            title: 'Autumn Years',
            description: 'Helping you plan for your',
            image: '../../static/4c977b860d8626cf6df963c393396672/tl-banner-3.jpg',
        },
    ];

    return (
        <Layout>
            <Seo
                title="Complete Financial Solutions"
                description="Throughlife Financial Solutions understand the importance of well-informed decision-making and provide a range of options and structures to assist you to achieve your financial goals"
            />
            <div className="slider-wrap">
                <div className="grid">
                    <div className="col-lg-12 col-bleed">
                        <Slider
                            autoplay={4000}
                            duration={2000}
                            nextButton={false}
                            previousButton={false}
                        >
                            {slides.map((slide, index) => (
                                <div
                                    className="sliderContent inner"
                                    key={index}
                                    style={{
                                        background: `url('${slide.image}') no-repeat center center`,
                                    }}
                                >
                                    <p>{slide.description}</p>
                                    <h1>{slide.title}</h1>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-8">
                            <p>
                                Throughlife Financial Solutions is a family
                                owned and operated financial planning practice.
                                Servicing our clients for over 30 years we
                                utilise a vast range of solutions to assist you
                                with your complete financial needs.
                            </p>
                            <p>
                                We take great pride in our reputation of
                                maintaining long term client relationships based
                                on quality advice and high end service.
                            </p>

                            <p>
                                We expect our clients' requirements to change
                                over time and because of this we appreciate the
                                need to be on hand to maintain a tailored
                                ongoing financial plan.
                            </p>

                            <p>
                                It is this commitment to building long-term
                                ongoing relationships which we believe is the
                                key to the success of our clients.
                            </p>
                            <p>
                                <strong>
                                    We cherish the freedom that comes from being
                                    privately owned
                                </strong>
                            </p>
                            <p>
                                Throughlife Financial Solutions Pty Ltd
                                maintains its own Australian Financial Services
                                Licence (AFSL). This means we are not owned by
                                any Bank, Fund Manager or Institution. As such,
                                there are no financial relationships that will
                                influence the advice that you receive.
                            </p>
                        </div>
                        <div className="col-lg-4 home--contact">
                            <p>
                                <strong>Phone:</strong>{' '}
                                <a href="tel: 9375 1149">(08) 9375 1149</a>
                                <br />
                                <strong>Email:</strong>{' '}
                                <a href="mailto: admin@throughlife.com.au">
                                    admin@throughlife.com.au
                                </a>
                            </p>
                            <p className="home--footer">
                                <Link to="/disclaimer">Disclaimer</Link>
                                <br />
                                ABN 49070265039
                                <br />
                                Australian Financial Services L/N: 221937
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
